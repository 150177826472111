import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/user';

const userInit = {
	"id": 0,
	"firstName": "",
	"LastName": "",
	"firstLast": "",
	"lastFirst": "",
	"userId": 0
};

export { userInit };

export const getActiveInspectors = () => {

	let url = `${apiRoot}/GetActiveInspectors`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch(e) {
		debugger;
	}
};





