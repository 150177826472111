export const PROFILE = {
	SET_PROFILE: 'SET_PROFILE',
	CLEAR_PROFILE: 'CLEAR_PROFILE',
};

export const APP_STATE = {
	API_DOWN: 'API_DOWN'
};

export const ORDER = {
	SET_ORDER: 'SET_ORDER'
};

export const PRODUCTS = {
	SET_SINGLE_PRODUCT: "SET_SINGLE_PRODUCT",
	GET_SINGLE_PRODUCT: "GET_SINGLE_PRODUCT",
	SET_PRODUCTS: 'SET_PRODUCTS',
	SET_DROPDOWN_LISTS: 'SET_DROPDOWN_LISTS'
};



export const CUSTOMER = {
	SET_CUSTOMER: 'SET_CUSTOMER',
	GET_CUSTOMER: 'GET_CUSTOMER',
	SET_CUSTOMER_LIST: 'SET_CUSTOMER_LIST',
	CLEAR_CUSTOMER_LIST: 'CLEAR_CUSTOMER_LIST',
	SET_CUSTOMER_SEARCH_TEXT: 'SET_CUSTOMER_SEARCH_TEXT',
	VERIFY_CUSTOMER: 'VERIFY_CUSTOMER',
};

export const AGENT = {
	SET_AGENT: 'SET_AGENT',
	GET_AGENT: 'GET_AGENT',
	SET_AGENT_LIST: 'SET_AGENT_LIST',
	CLEAR_AGENT_LIST: 'CLEAR_AGENT_LIST',
	SET_AGENT_SEARCH_TEXT: 'SET_AGENT_SEARCH_TEXT',
	VERIFY_AGENT: 'VERIFY_AGENT',
};

export const BUILDING = {
	SET_BUILDING: 'SET_BUILDING',
	GET_BUILDING: 'GET_BUILDING',
	SET_BUILDING_LIST: 'SET_BUILDING_LIST',
	CLEAR_BUILDING_LIST: 'CLEAR_BUILDING_LIST',
	SET_BUILDING_SEARCH_TEXT: 'SET_BUILDING_SEARCH_TEXT',
	VERIFY_BUILDING: 'VERIFY_BUILDING',
};

export const DEVICE = {
	SET_DEVICE: 'SET_DEVICE',
	SET_DEVICE_LIST: 'SET_DEVICE_LIST',
	CLEAR_DEVICE_LIST: 'CLEAR_DEVICE_LIST',
	SET_DEVICE_SEARCH_TEXT: 'SET_DEVICE_SEARCH_TEXT',
	VERIFY_DEVICE: 'VERIFY_DEVICE',
};


export const INSPECTION_DASHBOARD = {
	SET_DASHBOARD_NAV: 'SET_DASHBOARD_NAV',
	GET_DASHBOARD_NAV: 'GET_DASHBOARD_NAV',
};

export const SCHEDULER = {
	SET_INSPECTORS: 'SET_INSPECTORS',
	SET_WEEKLY_INSPECTIONS: 'SET_WEEKLY_INSPECTIONS',
	SET_WEEK_HEADER_ARRAY: 'SET_WEEK_HEADER_ARRAY',
	SET_SELECTED_DAY: 'SET_SELECTED_DAY',
	SET_SELECTED_INSPECTOR: 'SET_SELECTED_INSPECTOR',
	CLEAR_SELECTED_DAY: 'CLEAR_SELECTED_DAY',
	CLEAR_SELECTED_INSPECTOR: 'CLEAR_SELECTED_INSPECTOR',
	SET_PENDING_FILTER: 'SET_PENDING_FILTER',
	SET_PENDING_INSPECTIONS: 'SET_PENDING_INSPECTIONS',
	SET_SCHEDULE_NAV: 'SET_SCHEDULE_NAV',
	SET_FILTER_ARRAYS: 'SET_FILTER_ARRAYS',
	SET_MAP_CENTER: 'SET_MAP_CENTER',
	SET_MAP_TAB: 'SET_MAP_TAB',
	SET_SINGLE_INSPECTION: 'SET_SINGLE_INSPECTION',
	SET_SELECTED_INSP_ID: 'SET_SELECTED_INSP_ID',
	SET_SELECTED_INSP_LINE: 'SET_SELECTED_INSP_LINE',
	SET_INSP_LINE_MODAL: 'SET_INSP_LINE_MODAL',
	CLEAR_SELECTED_INSP_LINE: 'CLEAR_SELECTED_INSP_LINE',
};